.sidebarNav {
    padding: 15px 0;
    background-color: #f3f3f3;
    border-radius: 8px;
}

.dropdown {
    padding: 0;
}

.navbar-expand .navbar-nav {
    flex-direction: column;
}

.sidebarNav .section {
    position: relative;
}

.sidebarNav .section > div {
    display: inline-block;
}

.sidebarNav a {
    text-decoration: none;
    padding: 15px 0px;
}

@media screen and (max-width: 767px) {
    .navbar-expand .navbar-nav {
        flex-direction: row;
    }
}
