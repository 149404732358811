.timeline-element-in-progress {
    animation-name: pulse;
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    30%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.95);
    }
}
