.file-container {
    display: flex;
    margin: 10px 0px;
}

.file-preview {
    border: 1px solid #cccccccc;
    width: auto;
    display: inline-block;
    padding: 10px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50%;
}

.file-description {
    flex: 1;
    align-self: center;
}

label {
    margin-top: 10px;
}
