.htab-item {
    border-radius: 0.4rem;
    display: block;
    padding: 0.5rem 1rem;
    color: rgb(68, 68, 68);

    &.active {
        background-color: #e6e6e6;
        font-weight: 500;
    }
}
