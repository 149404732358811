.SideBar {
    /*background: #f3f3f3;*/
    height: 100vh;
    padding: 20px;
    min-height: 100vh;
}

.SideBar .logo {
    width: 100%;
    padding-right: 30%;
    margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
    #root .row {
        display: block;
    }

    .SideBar {
        width: 100%;
        display: block;
        height: auto;
        max-width: 100%;
    }

    .SideBar > * {
        vertical-align: top;
    }

    .SideBar .logo {
        display: inline-block;
        width: 33%;
        padding-right: 0;
    }
}
