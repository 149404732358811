.tableFixHead {
    overflow-y: auto;
    /* height: 200px; */
}

.tableFixHead table {
    border-collapse: collapse;
    width: 100%;
}

.tableFixHead th,
.tableFixHead td {
    padding: 8px 16px;
}

.tableFixHead th {
    position: sticky;
    top: 0;
    background: #eee;
}

.thead-dark th {
    padding: 8px 16px;
}
