body {
    font-size: 0.9em;
}

.text-primary {
    color: rgb(68, 68, 68) !important;
}

.bg-primary {
    background-color: rgb(68, 68, 68) !important;
}
.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled
{
    background-color: rgb(68, 68, 68);
    border-color: rgb(68, 68, 68);
}
.btn-primary:hover {
    background-color: rgb(48, 48, 48);
    border-color: rgb(68, 68, 68);
}
.btn-primary:focus {
    box-shadow: none;
}

.btn-link {
    color: rgb(68, 68, 68);
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: rgb(68, 68, 68);
    border-color: rgb(68, 68, 68);
}
.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: rgb(68, 68, 68);
}
.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem #88888870;
}

.form-control:focus {
    border-color: rgb(68, 68, 68);
    box-shadow: 0 0 0 0.2rem #88888870;
}

.page-item.active .page-link {
    background-color: rgb(68, 68, 68);
    border-color: rgb(68, 68, 68);
}
.page-link, .page-link:hover {
    color: rgb(68, 68, 68);
}
.page-link:focus {
    box-shadow: none;
}

.btn-light {
    background-color: #e6e6e6;
}

.table td {
    vertical-align: middle;
    padding: 0.2rem;
}

table td a {
    text-decoration: none;
}

.img-thumbnail {
    padding: 0;
    border-radius: 0.2rem;
}

#root .nav-tabs {
    border-bottom: 0;
}

#root .tabBody {
    background: #fbfbfb !important;
}

#root .nav-tabs .nav-link {
    border-color: #dee2e6 #dee2e6 #f8f8f8;
    border-radius: 1px;
    margin-right: 5px;
    border: 0;
    color: rgb(88, 88, 88);
}

#root .nav-tabs .nav-link:hover {
    color: rgb(56 , 56, 56);
}

#root a {
    text-decoration: none;
}



.feedback-alert .close:hover {
    color: #fbfbfb;
}
/*
#root #mi-report-table-container table tbody tr td:nth-child(even) {
    background: rgba(255, 255, 255, 0.12);
} */

.table-striped tbody tr:nth-of-type(odd) td {
    background-color: #fbfbfb;
}

.table-striped tbody tr:nth-of-type(even) td {
    background-color: #fff;
}

#mi-asking-price-list ol {
    list-style: none;
    margin-left: 0;
    counter-reset: line;
}
#mi-asking-price-list ol > li {
    position: relative;
    margin-bottom: 0.5em;
}
#mi-asking-price-list ol > li:before {
    position: absolute;
    left: -2.25em;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #00a9ce;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: 20px;
    counter-increment: line;
    content: counter(line);
    font-size: 13px;
}

th {
    font-weight: 400 !important;
}

#root table ::-webkit-scrollbar {
    width: 5px;
}
#root table ::-webkit-scrollbar-track {
    background: #ffffff;
}
#root table ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 2px;
    height: 3px;
    width: 2px;
    padding: 10px;
}

.pulse {
    animation-name: pulse;
    animation-timing-function: ease-in-out;
}

@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}
