#property-search {
    & .dropdown-item {
        padding: 0;
    }
    & .search-item {
        padding: 10px 15px;
        border-bottom: 1px solid #efefef;
    }
    & .search-item:hover {
        background-color: #fbfbfb;
    }
    & li:last-child .search-item {
        border-bottom: none;
    }
}
